var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "reg-notification",
    },
    [
      _c(
        "h3",
        {
          staticClass: "h3-notification-pageTitle",
        },
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("your") +
                  _vm.$t("all") +
                  _vm.$t("bulletin") +
                  _vm.$t("notify")
              ) +
              " "
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "row are-notification",
        },
        [
          _c(
            "div",
            {
              staticClass: "col-12 col-md-9 blk-notification-title",
            },
            [
              [
                _c(
                  "a",
                  {
                    staticClass: "lnk-notification-title",
                    attrs: {
                      href: "/dojo/evaluations/",
                    },
                  },
                  [_vm._v("課後評鑑通知")]
                ),
              ],
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass: "col-12 col-md-3 blk-notification-date",
            },
            [_vm._v("Nov 22,2021")]
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "row are-notification",
        },
        [
          _c(
            "div",
            {
              staticClass: "col-12 col-md-9 blk-notification-title",
            },
            [[_vm._m(0)]],
            2
          ),
          _c(
            "div",
            {
              staticClass: "col-12 col-md-3 blk-notification-date",
            },
            [_vm._v("Nov 22,2021")]
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "row are-notification",
        },
        [
          _c(
            "div",
            {
              staticClass: "col-12 col-md-9 blk-notification-title",
            },
            [
              [
                _c(
                  "span",
                  {
                    staticClass: "txt-notification-category",
                  },
                  [_vm._v("[" + _vm._s(_vm.$t("bulletin")) + "]")]
                ),
                _vm._v(" 公告標題 "),
              ],
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass: "col-12 col-md-3 blk-notification-date",
            },
            [_vm._v("Nov 22,2021")]
          ),
        ]
      ),
      _c("b-pagination", {
        attrs: {
          align: "center",
          "total-rows": "5",
          "per-page": 5,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "a",
      {
        staticClass: "lnk-notification-title",
        attrs: {
          href: "javascript:;",
        },
      },
      [
        _c("i", {
          staticClass: "fa fa-sign-in fa-lg",
        }),
        _vm._v("進教室通知 "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }